import React from "react";
import { Modal, Table } from "react-bootstrap";
import currencyFormat from "../../../utils/currencyFormat";

export default ({ data, ...otherProps }) => {
  const {
    created_at,
    start_at,
    username,
    password,
    no_of_logins,
    type,
    online_payment_completed,
    unit_price,
    total,
    receiver_model,
    payment_type,
  } = data || {};
  return (
    <Modal
      {...otherProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={"m-0"}>
          Connection Summary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Requested at</td>
              <td>{created_at}</td>
            </tr>
            <tr>
              <td>Start date</td>
              <td>{start_at}</td>
            </tr>
            <tr>
              <td>No of logins</td>
              <td>{no_of_logins}</td>
            </tr>
            <tr>
              <td>Connection type</td>
              <td className={"sentenceCase"}>{type}</td>
            </tr>
            <tr>
              <td>Unit Price</td>
              <td>{currencyFormat(unit_price)}</td>
            </tr>
            <tr>
              <td>Total Price</td>
              <td>{currencyFormat(total)}</td>
            </tr>
            <tr>
              <td>Payment method</td>
              <td className={"sentenceCase"}>{payment_type}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{username}</td>
            </tr>
            <tr>
              <td>Password</td>
              <td>{password}</td>
            </tr>
            <tr>
              <td>Receiver model</td>
              <td>{receiver_model || "Not provided"}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
